import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Layout from "../components/layout";

import Logo from "../icons/logo-big.svg";

const StyledLegalContainer = styled.article`
  display: grid;
  grid-template-columns: minmax(auto, 800px);
  justify-content: center;

  /* a {
    justify-self: center;
  } */
`;

const LayoutLegal = ({ children }) => {
  return (
    <Layout>
      <StyledLegalContainer className="padding">
        <Link to="/">
          <Logo />
        </Link>
        {children}
      </StyledLegalContainer>
    </Layout>
  );
};

export default LayoutLegal;
